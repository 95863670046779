$dark: #fff; // white...  dark blue
$brand: #ED0000; //  dark blue #61dafb; // electric blue

$primary: #ed0000;
$danger: #0074d9;
$input-focus-border-color: #252627;
$enable-shadows: false;
$input-focus-box-shadow: none;
$error: #D80000;
$warning: #B5962B;
@import "~bootstrap/scss/bootstrap";

:root{
  --bs-body-bg: #F7F9FA;
  --bs-alert-bg: #fde6e6;
}

.alert-danger {
  background-color: #fde6e6;
  border-color: $error;
  color: #222B2D;
}

.error-icon {
  color: $error;
}

.warning-icon{
  color: $warning;
}

.alert-warning {
  background-color: #fff3cd;
  border-color: $warning;
  color: #222B2D;
}

.logoblue {
  /* need to change ? https://codepen.io/sosuke/pen/Pjoqqp and https://css-tricks.com/change-color-of-svg-on-hover/ */
  filter: invert(43%) sepia(33%) saturate(3074%) hue-rotate(164deg) brightness(99%) contrast(101%);
}

.brandtotal img {
  height: 88px;
  margin: 12px 46px 12px 34px;
}

/* from DP portal start */

.tdf-tabs.container-fluid {
  background-color: #fff;
  padding: 1rem 2rem 0;
}

.main-flex {
  display: flex;
  flex-flow: column;
  height: 100%;
}

.dialog-off-canvas-main-canvas {
  width: 1920px;
  max-width: 100%;
  margin: 0 auto;
  position: relative;
}

.main-bg {
  z-index: -1;
}

.main-bg>div {
  background-color: #F7F9FA;
}

// bandeau
header {
  position: fixed;
  width: 100%;
  background-color: #fff;
  z-index: 10;
  max-width: 1920px
}

header .region-header {
  display: none
}

header>.navbar {
  padding-top: 0
}

header .navbar-collapse {
  padding: 0.8rem 1.5rem
}

header .region-nav-branding {
  font-size: 0.75rem;
  position: relative;
  border-bottom: 2px solid #F7F9FA;
}

header #block-topheader,
header #block-dpmyaccountmenulink,
header #block-dp-account-menu {
  margin-left: 0.5em;
  margin-right: 0.5em
}

header #block-topheader .contextual,
header #block-dpmyaccountmenulink .contextual,
header #block-dp-account-menu .contextual {
  display: none
}

header #block-topheader {
  display: none;
  padding-right: 1rem
}

header #block-topheader:after {
  content: "";
  width: 1px;
  height: 7px;
  background-color: var(--bs-body-color);
  display: inline-block;
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%)
}

header #block-dpmyaccountmenulink {
  position: relative;
  top: 6px
}

header #block-dpmyaccountmenulink a {
  line-height: 1
}

header #block-dpmyaccountmenulink a .info {
  font-size: 0.8em;
  opacity: 0.5
}

header a.account.nav-link {
  padding-right: 25px;
  background: url("/account.svg");
  background-size: 20px 20px;
  background-position: right 4px;
  background-repeat: no-repeat
}

header .account a.nav-link {
  color: var(--bs-body-color);
  padding-right: 25px;
  background: url("/account.svg");
  background-size: 20px 20px;
  background-position: right 4px;
  background-repeat: no-repeat
}

header #block-dp-branding .contextual {
  display: none
}

header #block-dp-branding a,
header #block-dp-branding-sitename a {
  transition: all 0.4s ease 0s;
}

header #block-dp-branding .navbar-brand {
  text-align: center;
  margin-left: 0;
  margin-right: 0;
  min-width: 140px
}

header #block-dp-branding-sitename {
  position: absolute;
  left: 0;
  top: 0
}

header #block-dp-branding-sitename .contextual {
  display: none
}

header #block-dp-branding-sitename .navbar-brand .site-title {
  display: none;
  letter-spacing: normal;
  text-transform: none;
  font-size: 0.875rem;
  color: #ED0000;
  font-weight: normal;
  padding-top: 0.3rem;
  padding-left: 0.5rem;
  padding-right: 0.5rem
}

header #block-mainsearchblock {
  position: absolute;
  top: -90px;
  left: 0;
  margin: 0;
  padding: 1rem;
  width: 100%;
  background-color: #fff
}

.navbar-brand img {
  height: 30px;
  margin: 0.2em 1.5rem
}

@media (min-width:992px) {
  header .region-header {
    display: block;
    width: 140px
  }

  header .navbar-collapse {
    padding: 0
  }

  header #block-mainsearchblock {
    position: relative;
    top: 0;
    left: 0;
    padding: 0;
    background-color: transparent;
    width: auto;
    margin-left: 180px
  }

  header #block-topheader {
    display: block
  }

  header #block-dp-branding-sitename .navbar-brand .site-title {
    display: block
  }

  #block-dp-branding {
    display: block
  }

  #block-dp-branding-sitename .site-logo {
    display: none !important
  }

  .navbar-brand img {
    height: 112px;
    margin: 0
  }
}

.navbar-brand a.site-title {
  text-decoration: none;
  color: #ED0000 !important;
}

.navbar-brand a.site-title:hover,
.navbar-brand a.site-title:focus {
  color: #be0000 !important;
}

.navbar-brand a.site-title:hover {
  color: #212529 !important;
  text-decoration: none
}

.navbar-brand .site-title {
  font-size: 1.5rem;
  font-weight: bold;
  letter-spacing: 2px;
  text-transform: uppercase
}

.navbar-brand img {
  height: 36px;
  margin: 0 1rem 0 0;
  width: auto
}

@media (min-width:992px) {
  .navbar-brand img {
    height: 112px
  }
}

.navbar-dark .navbar-brand a.site-title,
.footer-dark .navbar-brand a.site-title {
  color: #f8f9fa;
  text-decoration: none
}

.navbar-dark .navbar-brand a.site-title:hover,
.footer-dark .navbar-brand a.site-title:hover {
  color: #f8f9fa;
  text-decoration: none
}

.block {
  position: relative;
}

.block-superfishmain ul.menu.sf-menu li {
  background-color: #fff
}

.block-superfishmain ul.menu.sf-menu a {
  font-family: Roboto, sans-serif;
  font-weight: 500;
  color: #374649;
  text-transform: uppercase;
  font-size: 0.875rem
}

.block-superfishmain ul.menu.sf-menu a:hover,
.block-superfishmain ul.menu.sf-menu a.is-active:hover {
  text-decoration: none
}

.block-superfishmain ul.menu.sf-menu>li.menuparent>a {
  padding-right: 0.7rem;
  padding-right: 1.5rem
}

.block-superfishmain ul.menu.sf-menu>li.menuparent>a .sf-sub-indicator:after {
  content: "";
  position: absolute;
  top: 0;
  left: 0.25em;
  width: 0.5em;
  height: 0.5em;
  border-right: 2px solid #374649;
  border-bottom: 2px solid #374649;
  transform: rotate(45deg)
}

.block-superfishmain ul.menu.sf-menu>li>a {
  position: relative;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  text-decoration: none;
}

.block-superfishmain ul.menu.sf-menu>li>a:after {
  content: "";
  width: calc(100% - 1rem);
  height: 4px;
  background-color: transparent;
  position: absolute;
  bottom: 0;
  left: 0.5rem;
  border: none;
  padding: 0;
  margin: 0
}

.block-superfishmain ul.menu.sf-menu>li>a.active:after,
.block-superfishmain ul.menu.sf-menu>li>a.is-active:after,
.block-superfishmain ul.menu.sf-menu>li>a.active-trail:after {
  background-color: #ED0000
}

.block-superfishmain ul.menu.sf-menu>li>a:hover,
.block-superfishmain ul.menu.sf-menu>li>a.is-active:hover {
  color: #ED0000;
  text-decoration: none
}

.block-superfishmain ul.menu.sf-menu>li>a:hover .sf-sub-indicator:after,
.block-superfishmain ul.menu.sf-menu>li>a.is-active:hover .sf-sub-indicator:after {
  border-right: 2px solid #ED0000;
  border-bottom: 2px solid #ED0000
}

.block-superfishmain ul.menu.sf-menu>li li a {
  padding: 0.5rem
}

.block-superfishmain ul.menu.sf-menu>li li a:hover {
  background-color: #e9ecef
}

.block-superfishmain ul.menu.sf-menu>li li a .sf-sub-indicator:after {
  content: "";
  position: absolute;
  top: 0.25em;
  left: 0.25em;
  width: 0.5em;
  height: 0.5em;
  border-right: 2px solid #374649;
  border-bottom: 2px solid #374649;
  transform: rotate(-45deg)
}

.block-superfishmain ul.menu.sf-menu>li.active-trail>a:after {
  background-color: #ED0000
}

.block-superfish {
  width: 100%;
}

ul.sf-menu,
ul.sf-menu li {
  list-style: none;
}

ul.sf-menu,
ul.sf-menu ul,
ul.sf-menu ol,
ul.sf-menu li,
ul.sf-menu a,
ul.sf-menu span,
ul.sf-menu div {
  margin: 0;
  padding: 0;
}

ul.sf-menu {
  line-height: 1.0;
  z-index: 497;
}

ul.sf-menu ul {
  left: 0;
  position: absolute;
  top: -9999px;
  width: 12em;
}

ul.sf-menu ul li {
  width: 100%;
}

ul.sf-menu li {
  float: left;
  position: relative;
  z-index: 498;
}

ul.sf-menu a,
ul.sf-menu span.nolink {
  display: block;
  padding: 1em 1.5em;
  position: relative;
}

ul.sf-menu li:hover,
ul.sf-menu li.sfHover,
ul.sf-menu li:hover ul,
ul.sf-menu li.sfHover ul {
  z-index: 499;
}

ul.sf-menu li:hover>ul,
ul.sf-menu li.sfHover>ul {
  left: 0;
  top: 2.5em;
}

ul.sf-menu li li:hover>ul,
ul.sf-menu li li.sfHover>ul {
  left: 12em;
  top: 0;
}

ul.sf-menu.menu,
ul.sf-menu.menu ul,
ul.sf-menu.menu li {
  margin: 0;
  padding: 0;
}

ul.sf-menu .sf-with-ul {
  padding-right: 3em;
}

ul.sf-menu .sf-sub-indicator {
  height: 12px;
  line-height: 9999px;
  opacity: 0.75;
  overflow: hidden;
  position: absolute;
  right: 0.75em;
  text-indent: -9999px;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%);
  width: 12px;
}

ul.sf-menu .sf-sub-indicator:after {
  content: "▼";
  left: 0;
  line-height: 1;
  position: absolute;
  text-indent: 0;
  top: 0;
}

ul.sf-menu ul .sf-sub-indicator:after {
  content: "►";
}

ul.sf-menu a:focus>.sf-sub-indicator,
ul.sf-menu a:hover>.sf-sub-indicator,
ul.sf-menu a:active>.sf-sub-indicator,
ul.sf-menu li:hover>a>.sf-sub-indicator,
ul.sf-menu li.sfHover>a>.sf-sub-indicator,
ul.sf-menu span.nolink:hover>.sf-sub-indicator,
ul.sf-menu li:hover>span.nolink>.sf-sub-indicator,
ul.sf-menu li.sfHover>span.nolink>.sf-sub-indicator {
  opacity: 1;
}

ul.sf-menu.sf-horizontal.sf-shadow ul,
ul.sf-menu.sf-vertical.sf-shadow ul,
ul.sf-menu.sf-navbar.sf-shadow ul ul {
  -webkit-box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.5);
  -moz-box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.5);
  box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.5);
}

ul.sf-menu.sf-shadow ul.sf-shadow-off {
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
}

ul.sf-menu li.sf-multicolumn-column {
  display: inline;
  float: left;
}

ul.sf-menu li.sf-parent-children-1 ul.sf-multicolumn {
  width: 12em;
}

ul.sf-menu li.sf-parent-children-2 ul.sf-multicolumn {
  width: 24em;
}

ul.sf-menu li.sf-parent-children-3 ul.sf-multicolumn {
  width: 36em;
}

ul.sf-menu li.sf-parent-children-4 ul.sf-multicolumn {
  width: 48em;
}

ul.sf-menu li.sf-parent-children-5 ul.sf-multicolumn {
  width: 60em;
}

ul.sf-menu li.sf-parent-children-6 ul.sf-multicolumn {
  width: 72em;
}

ul.sf-menu li.sf-parent-children-7 ul.sf-multicolumn {
  width: 84em;
}

ul.sf-menu li.sf-parent-children-8 ul.sf-multicolumn {
  width: 96em;
}

ul.sf-menu li.sf-parent-children-9 ul.sf-multicolumn {
  width: 108em;
}

ul.sf-menu li.sf-parent-children-10 ul.sf-multicolumn {
  width: 120em;
}

ul.sf-menu li.sf-multicolumn-column:nth-child(1):nth-last-child(2),
ul.sf-menu li.sf-multicolumn-column:nth-child(2):nth-last-child(1) {
  width: 50%;
}

ul.sf-menu li.sf-multicolumn-column:nth-child(1):nth-last-child(3),
ul.sf-menu li.sf-multicolumn-column:nth-child(2):nth-last-child(2),
ul.sf-menu li.sf-multicolumn-column:nth-child(3):nth-last-child(1) {
  width: 32.333%;
}

ul.sf-menu li.sf-multicolumn-column:nth-child(1):nth-last-child(4),
ul.sf-menu li.sf-multicolumn-column:nth-child(2):nth-last-child(3),
ul.sf-menu li.sf-multicolumn-column:nth-child(3):nth-last-child(2),
ul.sf-menu li.sf-multicolumn-column:nth-child(4):nth-last-child(1) {
  width: 25%;
}

ul.sf-menu li.sf-multicolumn-column:nth-child(1):nth-last-child(5),
ul.sf-menu li.sf-multicolumn-column:nth-child(2):nth-last-child(4),
ul.sf-menu li.sf-multicolumn-column:nth-child(3):nth-last-child(3),
ul.sf-menu li.sf-multicolumn-column:nth-child(4):nth-last-child(2),
ul.sf-menu li.sf-multicolumn-column:nth-child(5):nth-last-child(1) {
  width: 20%;
}

ul.sf-menu li.sf-multicolumn-column:nth-child(1):nth-last-child(6),
ul.sf-menu li.sf-multicolumn-column:nth-child(2):nth-last-child(5),
ul.sf-menu li.sf-multicolumn-column:nth-child(3):nth-last-child(4),
ul.sf-menu li.sf-multicolumn-column:nth-child(4):nth-last-child(3),
ul.sf-menu li.sf-multicolumn-column:nth-child(5):nth-last-child(2),
ul.sf-menu li.sf-multicolumn-column:nth-child(6):nth-last-child(1) {
  width: 16.665%;
}

ul.sf-menu li.sf-multicolumn-column:nth-child(1):nth-last-child(7),
ul.sf-menu li.sf-multicolumn-column:nth-child(2):nth-last-child(6),
ul.sf-menu li.sf-multicolumn-column:nth-child(3):nth-last-child(5),
ul.sf-menu li.sf-multicolumn-column:nth-child(4):nth-last-child(4),
ul.sf-menu li.sf-multicolumn-column:nth-child(5):nth-last-child(3),
ul.sf-menu li.sf-multicolumn-column:nth-child(6):nth-last-child(2),
ul.sf-menu li.sf-multicolumn-column:nth-child(7):nth-last-child(1) {
  width: 14.2855%;
}

ul.sf-menu li.sf-multicolumn-column:nth-child(1):nth-last-child(8),
ul.sf-menu li.sf-multicolumn-column:nth-child(2):nth-last-child(7),
ul.sf-menu li.sf-multicolumn-column:nth-child(3):nth-last-child(6),
ul.sf-menu li.sf-multicolumn-column:nth-child(4):nth-last-child(5),
ul.sf-menu li.sf-multicolumn-column:nth-child(5):nth-last-child(4),
ul.sf-menu li.sf-multicolumn-column:nth-child(6):nth-last-child(3),
ul.sf-menu li.sf-multicolumn-column:nth-child(7):nth-last-child(2),
ul.sf-menu li.sf-multicolumn-column:nth-child(8):nth-last-child(1) {
  width: 14.2855%;
}

ul.sf-menu li.sf-multicolumn-column:nth-child(1):nth-last-child(9),
ul.sf-menu li.sf-multicolumn-column:nth-child(2):nth-last-child(8),
ul.sf-menu li.sf-multicolumn-column:nth-child(3):nth-last-child(7),
ul.sf-menu li.sf-multicolumn-column:nth-child(4):nth-last-child(6),
ul.sf-menu li.sf-multicolumn-column:nth-child(5):nth-last-child(5),
ul.sf-menu li.sf-multicolumn-column:nth-child(6):nth-last-child(4),
ul.sf-menu li.sf-multicolumn-column:nth-child(7):nth-last-child(3),
ul.sf-menu li.sf-multicolumn-column:nth-child(8):nth-last-child(2),
ul.sf-menu li.sf-multicolumn-column:nth-child(9):nth-last-child(1) {
  width: 11.111%;
}

ul.sf-menu li.sf-multicolumn-column:nth-child(1):nth-last-child(10),
ul.sf-menu li.sf-multicolumn-column:nth-child(2):nth-last-child(9),
ul.sf-menu li.sf-multicolumn-column:nth-child(3):nth-last-child(8),
ul.sf-menu li.sf-multicolumn-column:nth-child(4):nth-last-child(7),
ul.sf-menu li.sf-multicolumn-column:nth-child(5):nth-last-child(6),
ul.sf-menu li.sf-multicolumn-column:nth-child(6):nth-last-child(5),
ul.sf-menu li.sf-multicolumn-column:nth-child(7):nth-last-child(4),
ul.sf-menu li.sf-multicolumn-column:nth-child(8):nth-last-child(3),
ul.sf-menu li.sf-multicolumn-column:nth-child(9):nth-last-child(2),
ul.sf-menu li.sf-multicolumn-column:nth-child(10):nth-last-child(1) {
  width: 10%;
}

ul.sf-menu.sf-vertical {
  width: 12em;
}

ul.sf-menu.sf-vertical li {
  width: 100%;
}

ul.sf-menu.sf-vertical li:hover>ul,
ul.sf-menu.sf-vertical li.sfHover>ul {
  left: 12em;
  top: 0;
}

ul.sf-menu.sf-vertical .sf-sub-indicator:after {
  content: "►";
}

ul.sf-menu.sf-navbar {
  position: relative;
}

ul.sf-menu.sf-navbar li {
  position: static;
}

ul.sf-menu.sf-navbar li li {
  position: relative;
}

ul.sf-menu.sf-navbar li ul,
ul.sf-menu.sf-navbar li li li {
  width: 100%;
}

ul.sf-menu.sf-navbar li li {
  width: auto;
  float: left;
}

ul.sf-menu.sf-navbar li li:hover>ul,
ul.sf-menu.sf-navbar li li.sfHover>ul,
ul.sf-menu.sf-navbar>li.active-trail>ul {
  left: 0;
  top: 2.5em;
}

ul.sf-menu.sf-navbar li li li:hover>ul,
ul.sf-menu.sf-navbar li li li.sfHover>ul {
  left: 12em;
  top: 0;
}

ul.sf-menu.sf-navbar ul .sf-sub-indicator:after {
  content: "▼";
}

ul.sf-menu.sf-navbar ul ul .sf-sub-indicator:after {
  content: "►";
}

ul.sf-menu.sf-accordion {
  display: none;
  position: absolute;
}

ul.sf-menu.sf-accordion,
ul.sf-menu.sf-accordion ul,
ul.sf-menu.sf-accordion li {
  float: left;
  width: 100%;
}

ul.sf-menu.sf-accordion ul {
  margin: 0;
  padding: 0;
}

ul.sf-menu.sf-accordion.sf-expanded,
ul.sf-menu.sf-accordion li.sf-expanded>ul {
  left: auto !important;
  position: relative;
  top: auto !important;
}

ul.sf-menu.sf-accordion .sf-sub-indicator:after {
  content: "▼";
}

ul.sf-menu.sf-accordion.sf-accordion-with-buttons .sf-accordion-button:after {
  content: "▼";
  margin: 0 0 0 1em;
}

ul.sf-menu.sf-accordion.sf-accordion-with-buttons li.sf-expanded>.sf-accordion-button:after {
  content: "▲";
}

ul.sf-menu.rtl,
ul.sf-menu.rtl li {
  float: right;
}

ul.sf-menu.rtl li:hover>ul,
ul.sf-menu.rtl li.sfHover>ul {
  left: auto;
  right: 0;
}

ul.sf-menu.rtl li li:hover>ul,
ul.sf-menu.rtl li li.sfHover>ul {
  left: auto;
  right: 12em;
}

ul.sf-menu.rtl ul {
  left: auto;
  right: 0;
}

ul.sf-menu.rtl .sf-sub-indicator {
  left: 0.75em;
  right: auto;
}

ul.sf-menu.rtl.sf-horizontal ul .sf-sub-indicator:after {
  content: "◄";
}

ul.sf-menu.rtl.sf-horizontal.sf-shadow ul,
ul.sf-menu.rtl.sf-vertical.sf-shadow ul,
ul.sf-menu.rtl.sf-navbar.sf-shadow ul ul {
  -webkit-box-shadow: -2px 2px 10px rgba(0, 0, 0, 0.5);
  -moz-box-shadow: -2px 2px 10px rgba(0, 0, 0, 0.5);
  box-shadow: -2px 2px 10px rgba(0, 0, 0, 0.5);
}

ul.sf-menu.rtl li.sf-multicolumn-column {
  float: right;
}

ul.sf-menu.rtl.sf-vertical li:hover>ul,
ul.sf-menu.rtl.sf-vertical li.sfHover>ul {
  left: auto;
  right: 12em;
}

ul.sf-menu.rtl.sf-vertical .sf-sub-indicator:after {
  content: "◄";
}

ul.sf-menu.rtl.sf-navbar li li {
  float: right;
}

ul.sf-menu.rtl.sf-navbar ul ul .sf-sub-indicator:after {
  content: "◄";
}

ul.sf-menu.rtl.sf-navbar li li:hover>ul,
ul.sf-menu.rtl.sf-navbar li li.sfHover>ul {
  left: auto;
  right: 0;
}

ul.sf-menu.rtl.sf-navbar li li li:hover>ul,
ul.sf-menu.rtl.sf-navbar li li li.sfHover>ul {
  left: auto;
  right: 12em;
}

ul.sf-menu.rtl.sf-accordion.sf-expanded,
ul.sf-menu.rtl.sf-accordion li.sf-expanded>ul {
  right: auto !important;
}





main{
  padding-top: 112px; 
  transition: padding-top 0.4s ease 0s;
}

#block-homeheaderblock {
  width:1280px;
  margin:auto;
  max-width:100%
}

  #block-homeheaderblock #logo{
    height: 200px;
    /* width: 200px; */
  }


  #block-homeheaderblock #Heading {
    color: $brand;
    font-weight: bold;
    font-size: 3.2rem;
    margin: 2rem 0;
  }

  #block-homeheaderblock #SubHeading {

    line-height: 2;
    font-size: 1.6rem;
    color: #212529
  }

  .btn {
    font-weight: 500
}


.btn-primary {
  color: #fff;
  background-color: #ed0000;
  border-color: #ed0000
}

.btn-primary:hover {
  color: #ed0000;
  background-color: #fff;
  border-color: #ed0000
}

.btn-check:focus+.btn-primary,.btn-primary:focus {
  color: #ed0000;
  background-color: #fff;
  border-color: #ed0000;
  box-shadow: 0 0 0 .25rem rgba(240,38,38,.5)
}

.btn-check:checked+.btn-primary,.btn-check:active+.btn-primary,.btn-primary:active,.btn-primary.active,.show>.btn-primary.dropdown-toggle {
  color: #ed0000;
  background-color: #fff;
  border-color: #ed0000
}

.btn-check:checked+.btn-primary:focus,.btn-check:active+.btn-primary:focus,.btn-primary:active:focus,.btn-primary.active:focus,.show>.btn-primary.dropdown-toggle:focus {
  box-shadow: 0 0 0 .25rem rgba(240,38,38,.5)
}

.btn-primary:disabled,.btn-primary.disabled {
  color: #8a8a8a;
  background-color: #edeff0;
  border-color: #edeff0
}



.navigationtoolbar .btn-primary {
  color: black;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  border-top: 0;
  border-left: 0;
  border-right: 0;
  border-radius: 0%;
  background-color: transparent;
}

.navigationtoolbar .btn-primary:not(:disabled) [data-icon] {
  color: #ed0000;
}

.navigationtoolbar .btn-primary:hover {
  color: black;
  background-color: transparent;
  border-bottom: 2px solid #252627;
}

.navigationtoolbar .btn-check:focus+.btn-primary,
.navigationtoolbar .btn-primary:focus {
  color: black;
  background-color: transparent;
  border-bottom: 2px solid #252627;
}

.navigationtoolbar .btn-check:checked+.btn-primary,
.navigationtoolbar .btn-check:active+.btn-primary,
.navigationtoolbar .btn-primary:active,
.navigationtoolbar .btn-primary.active,
.navigationtoolbar .show>.btn-primary.dropdown-toggle {
  color: black;
  font-weight: bold;
  background-color: transparent;
  border-bottom: 2px solid #252627;
}

.navigationtoolbar .btn-check:checked+.btn-primary:focus,
.navigationtoolbar .btn-check:active+.btn-primary:focus,
.navigationtoolbar .btn-primary:active:focus,
.navigationtoolbar .btn-primary.active:focus,
.navigationtoolbar .show>.btn-primary.dropdown-toggle:focus {
  color: black;
  font-weight: bold;
  background-color: transparent;
  border-bottom: 2px solid #252627;
}

.navigationtoolbar .btn-primary:disabled {
  color: #8a8a8a;
  background-color: transparent;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}


.btn-secondary {
  color: #ed0000;
  background-color: #fff;
  border-color: #ed0000
}

.btn-secondary:hover {
  color: #fff;
  background-color: #ed0000;
  border-color: #ed0000
}

.btn-check:focus+.btn-secondary,.btn-secondary:focus {
  color: #fff;
  background-color: #ed0000;
  border-color: #ed0000;
  box-shadow: 0 0 0 .25rem rgba(237,0,0,.5)
}

.btn-check:checked+.btn-secondary,.btn-check:active+.btn-secondary,.btn-secondary:active,.btn-secondary.active,.show>.btn-secondary.dropdown-toggle {
  color: #fff;
  background-color: #ed0000;
  border-color: #ed0000
}

.btn-check:checked+.btn-secondary:focus,.btn-check:active+.btn-secondary:focus,.btn-secondary:active:focus,.btn-secondary.active:focus,.show>.btn-secondary.dropdown-toggle:focus {
  box-shadow: 0 0 0 .25rem rgba(237,0,0,.5)
}

.btn-secondary:disabled,.btn-secondary.disabled {
  color: #8a8a8a;
  background-color: #fff;
  border-color: #edeff0
}


.btn-tertiary {
    color: #ed0000;
    background-color: transparent;
    border-color: transparent
}

.btn-tertiary:hover {
    color: #ed0000;
    background-color: transparent;
    border-color: transparent
}

.btn-check:focus+.btn-tertiary,.btn-tertiary:focus {
    color: #ed0000;
    background-color: transparent;
    border-color: transparent;
    box-shadow: 0 0 0 .25rem rgba(237,0,0,.5)
}

.btn-check:checked+.btn-tertiary,.btn-check:active+.btn-tertiary,.btn-tertiary:active,.btn-tertiary.active,.show>.btn-tertiary.dropdown-toggle {
    color: #ed0000;
    background-color: transparent;
    border-color: transparent
}

.btn-check:checked+.btn-tertiary:focus,.btn-check:active+.btn-tertiary:focus,.btn-tertiary:active:focus,.btn-tertiary.active:focus,.show>.btn-tertiary.dropdown-toggle:focus {
    box-shadow: 0 0 0 .25rem rgba(237,0,0,.5)
}

.btn-tertiary:disabled,.btn-tertiary.disabled {
    color: #8a8a8a;
    background-color: transparent;
    border-color: transparent
}

.btn-tertiary:hover,.btn-tertiary:focus {
    text-decoration: underline
}

.btn-primary {
    font-size: 0.875rem
}

.btn-secondary {
    font-size: 0.875rem
}

.btn-tertiary {
    font-size: 0.875rem
}

.btn-success {
    font-size: 0.875rem
}

.btn-warning {
    font-size: 0.875rem
}

.btn-danger {
    font-size: 0.875rem
}

.btn-light {
    font-size: 0.875rem
}

.btn [class^=material-icons] {
    position: relative;
    vertical-align: top;
    font-size: 1.25rem
}

.btn-circle.btn-tertiary:hover,.btn-circle.btn-tertiary:focus {
    color: #fff;
    background-color: #ed0000
}

.region-masthead:not(.no-background) {
  background: url(/bg.jpg);
  background-size: 100% 100%;
}

.region-masthead {
  position: relative;
  padding: 1rem;
}


/* from DP portal end */

.region-masthead  {
  display: flex;
  justify-content: space-around
}


.region-masthead #logo{
  height: 64px;
}

.bgwhite{
  background-color: white;
}

#block-dp-breadcrumbs {
  flex-grow: 1;
}
#block-dp-breadcrumbs span {
  color: red;
  font-size: xx-large;
}

.browserlink {
  // font-family: "Segoe UI", "Segoe UI Web (West European)", "Segoe UI", -apple-system, BlinkMacSystemFont, Roboto, "Helvetica Neue", sans-serif;
  // -webkit-font-smoothing: antialiased;
  // font-size: inherit;
  // font-weight: inherit;
  background: none transparent;
  border: none;
  // cursor: pointer;
  // display: inline;
  // margin: 0px;
  // overflow: inherit;
  // padding: 0px;
  // text-align: left;
  // text-overflow: inherit;
  // border-bottom: 1px solid transparent;
  -webkit-user-select: text;
  user-select: text;
}

.browserlink a {
  color: black;
  outline: none;
  text-decoration: none;

}

.notvisible {
  visibility: hidden;
  display: none;
}

.folderConfirm {
  width: 100px;
  margin-left: 10px;

}

.radiocentered {
  vertical-align: middle;
}

.permTable th:nth-child(0n+1) {
  width: 16.66666667%
}

.permTable th:nth-child(0n+2) {
  width: 16.66666667%
}

.permTable th:nth-child(0n+3) {
  width: 25%;
}

.permTable th:nth-child(0n+4) {
  width: 33.33333333%;
  ;
}

.permTable th:nth-child(0n+5) {
  width: 8.33333333%;
}



.smaller {
  font-size: .500em;
}


.breadcrumb-item a {
    color: #ed0000;
    position: relative;
    text-decoration: none;
}
.breadcrumb-item a:hover {
  color: #ed0000;
  position: relative;
  text-decoration: none;
}
.form-check-input:checked {
  background-color: #ed0000;
  border-color: #ed0000;
}

#home #explanation {
  padding: 30px;
  color: white;

  position: relative;  
  background-image: url(/SL-101820-36860-14.jpg);
  background-size: cover;  
  background-position: bottom;
  background-repeat: no-repeat;  
  height: calc(100vh - 211px);
}
#home h2 {
  padding-top: 20px;
  text-align: center;
}
#home h5 {
  padding-top: 20px;
  text-align: center;
}



.flashsearch  .form-control {
  /* top | right | bottom | left */
  // left padding = 2*left of before + width of image
  padding: 0.375rem 0.75rem 0.375rem 32px;
  background-color: white;
  height: auto;
}

.flashsearch .form-control::placeholder {
  color: $input-placeholder-color;
  // Override Firefox's unusual default opacity; see https://github.com/twbs/bootstrap/pull/11526.
  opacity: 1;
}

.flashsearch::before {
  background-image: url("data:image/svg+xml,%3Csvg aria-hidden='true' focusable='false' data-prefix='fas' data-icon='magnifying-glass' class='svg-inline--fa fa-magnifying-glass ' role='img' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512'%3E%3Cpath fill='currentColor' d='M416 208c0 45.9-14.9 88.3-40 122.7L502.6 457.4c12.5 12.5 12.5 32.8 0 45.3s-32.8 12.5-45.3 0L330.7 376c-34.4 25.2-76.8 40-122.7 40C93.1 416 0 322.9 0 208S93.1 0 208 0S416 93.1 416 208zM208 352a144 144 0 1 0 0-288 144 144 0 1 0 0 288z'%3E%3C/path%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-position: center;
  content: " ";
  color: rgba(var(--bs-body-color-rgb), 0.65);
  display: inline-block;
  width: 16px;
  position: absolute;
  height: 100%;
  left: 8px;
  z-index: 100;
} 

.flashsearch button {
  left: calc(-1.25em - 16px );
  z-index: 100;
}
.flashsearch .form-control  ~ button {
  color: black ;
  background-color: transparent;
  border-color: transparent;
  box-shadow: none;
}
.flashsearch .form-control:placeholder-shown ~ button {
  color: transparent;
  
}

#home .card {
  margin-top: 20px;
  cursor: pointer;
  border-color: grey;
  background-color: #FFFFFF;
}
#home .card-body {
  min-height: 80px;
  border-radius: 16px;
  line-height: 20px;
  font-weight: 500;
}

.opaque {
  opacity: 0.3;
}

#progressDownload{
  background-color: white;
}

.upload_dropZone {
  color: #0f3c4b;
  outline: 2px dashed var(--colorPrimaryHalf, #c1ddef);
  outline-offset: -12px;
  transition:
    outline-offset 0.2s ease-out,
    outline-color 0.3s ease-in-out,
    background-color 0.2s ease-out;
}
.upload_dropZone.highlight {
  outline-offset: -4px;
  outline-color: var(--colorPrimaryNormal, #0576bd);
  background-color: var(--colorPrimaryEighth, #c8dadf);
}


#tableFiles{
  width: unset;
}

#tableFiles tr>:nth-child(1) {
  min-width: 16px;
  max-width: 24px; 
  width: 24px;
}

#tableFiles tr>:nth-child(1) :first-child {
  vertical-align: text-bottom;
}


#tableFiles tr>:nth-child(2) {
  min-width: 16px;
  max-width: 24px; 
  width: 24px;
}

#tableFiles tr>:nth-child(2) :first-child {
  display: inline;
  vertical-align: text-bottom;
}

#tableFiles tr>:nth-child(4),
#tableFiles tr>:nth-child(5) {
    width: 250px;
}
#tableFiles tr>:nth-child(6) {
  width: 100px;
}

#tableFolders{
  font-size: smaller;
  width: 1%;
}

#tableFolders td, 
#tableFolders th {
    white-space: nowrap;
    width: 1%;
}

#tableFolders tr>:nth-child(1) {
  min-width: 16px;
  max-width: 24px; 
}

#tableFolders tr>:nth-child(2) {
  min-width: 300px;
}


.modal-dialog{
  overflow-y: initial !important
}
.modal-body{
  max-height: 500px;
  overflow-y: auto;
}


.text-right{
  text-align: right;
}

.alertmini {
  font-size: small;
}

.thead-th.sortable:not([data-sort-order]) span {
  visibility: hidden;
}


// from https://github.com/riyadelberkawy/formik-stepper/blob/c13af2052ebd9e1f51a72261e87da293ba53b5e8/src/stepper/styles.css#L46 START
.stepper-horizontal {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  width: 100%;
  margin: 0 auto;
}
.stepper-horizontal .stepper-step {
  width: -moz-fit-content;
  width: fit-content ;
  display: table-cell;
  position: relative;
  padding: 24px;
}
.stepper-horizontal .stepper-step:active {
  border-radius: 15% / 75%;
}
.stepper-horizontal .stepper-step:first-child:active {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.stepper-horizontal .stepper-step:last-child:active {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.stepper-horizontal .stepper-step:hover .stepper-circle {
  background-color: #757575;
}
.stepper-horizontal .stepper-step .stepper-circle {
  width: 24px;
  height: 24px;
  margin: 0 auto;
  background-color: #9e9e9e;
  border-radius: 50%;
  text-align: center;
  line-height: 2em;
  font-size: 12px;
  color: white;
}
.stepper-horizontal .stepper-step.active-step .stepper-circle {
  background-color: #2196f3;
}

.stepper-horizontal .stepper-step.editable-step .stepper-circle {
  -moz-transform: scaleX(-1);
  /* Gecko */
  -o-transform: scaleX(-1);
  /* Opera */
  -webkit-transform: scaleX(-1);
  /* Webkit */
  transform: scaleX(-1);
  /* Standard */
}
.stepper-horizontal .stepper-step.editable-step .stepper-circle * {
  display: none;
}
.stepper-horizontal .stepper-step.editable-step .stepper-circle:before {
  content: "\270E";
}
.stepper-horizontal .stepper-step .stepper-title {
  margin-top: 16px;
  font-size: 14px;
  font-weight: normal;
  color: #8c8c8c;
}
.stepper-horizontal .stepper-step .stepper-optional {
  color: #262626;
}
.stepper-horizontal .stepper-step.active-step .stepper-title {
  font-weight: 500;
  color: #1b1b1b;
}
.stepper-horizontal .stepper-step.active-step.step-done .stepper-title,
.stepper-horizontal .stepper-step.active-step.editable-step .stepper-title {
  font-weight: 300;
}
.stepper-horizontal .stepper-step .stepper-optional {
  font-size: 12px;
}
.stepper-horizontal .stepper-step.active-step .stepper-optional {
  color: rgba(0, 0, 0, 0.54);
}
.stepper-horizontal .stepper-step .stepper-bar-left {
  position: absolute;
  top: 36px;
  height: 1px;
  border-top: 1px solid #bdbdbd;
}
.stepper-horizontal .stepper-step .stepper-bar-right {
  position: absolute;
  top: 36px;
  height: 1px;
  border-top: 1px solid #bdbdbd;
  right: 0;
  left: 50%;
  margin-left: 20px;
}
.stepper-horizontal .stepper-step .stepper-bar-left {
  left: 0;
  right: 50%;
  margin-right: 20px;
}

// from https://github.com/riyadelberkawy/formik-stepper/blob/c13af2052ebd9e1f51a72261e87da293ba53b5e8/src/stepper/styles.css#L46 END

.stepper-horizontal .stepper-step .stepper-circle {
  opacity: 0.43;
}

.stepper-horizontal .stepper-step.active-step .stepper-circle {
  background-color: #ed0000 !important;
  opacity: 1;
}

.stepper-horizontal .stepper-step .stepper-title {
  opacity: 0.43;
}

.stepper-horizontal .stepper-step.active-step .stepper-title {
  opacity: 1;
}


.form-group {
  margin-bottom: 1rem;
}

#adminFormHelp{
  min-height: '100px';
}

.disabledNavlink {
  cursor: default ;
}

select.form-control {
  appearance: auto;
}

// style BasicFooter start

.navigation-wrapper
{
  margin-top: 1em; 
  display: flex;
}

.navigation-wrapper button {
  color: #fff;
  display: inline-block;
  font-weight: 400;
  line-height: 1.5;
  text-align: center;
  text-decoration: none;
  vertical-align: middle;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
  border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.navigation-wrapper button[type=button] {
  // previous
  background-color: rgb(255, 255, 255);
  color: rgb(237, 0, 0);
  border-color: rgb(237, 0, 0);
}
.navigation-wrapper div{
  // next, save to right side 
  margin-inline-start: auto;
}

.navigation-wrapper button[type=submit] {
  // next, save
  background-color: rgb(237, 0, 0);
}
.navigation-wrapper button[disabled] {
  // next, save
  background-color: grey;
}
// style BasicFooter end


// for move popup
.movepopup {
  -webkit-user-select: none;
  user-select: none;
  padding: 20px;
  border-radius: 0.4em;
}

.movepopup .tree,
.movepopup .tree-node,
.movepopup .tree-node-group {
  list-style: none;
  margin: 0;
  padding: 0;
}

.movepopup .tree-branch-wrapper,
.movepopup .tree-node__leaf {
  outline: none;
}

.movepopup .tree-node {
  cursor: pointer;
}

.movepopup .tree-node:hover {
  background: rgba(255, 255, 255, 0.1);
}

.movepopup .tree .tree-node--focused {
  background: rgba(255, 255, 255, 0.2);
  outline-color: rgba(255, 255, 255, 0.2);
  outline-style: auto;
  outline-width: 2px;    

     
}

.movepopup .tree .tree-node--selected .name {
  outline-color: rgb(77, 144, 254);
  outline-style: auto;
  outline-width: 2px;  
}


.movepopup .tree-node__branch {
  display: flex;
  align-items: center;
}

.movepopup .icon {
  color: rgb(232, 168, 124);
  width: 1em;
  height: 1em;
}
.movepopup .name {
  padding-left: 5px;
}
// end for move popup



ul.pagination {
  margin-bottom: 0;
}

// tables from dp portal

.table {
  --bs-table-bg: transparent;
  --bs-table-accent-bg: transparent;
  --bs-table-striped-color: #374649;
  --bs-table-striped-bg: rgba(0,0,0,0.05);
  --bs-table-active-color: #374649;
  --bs-table-active-bg: rgba(0,0,0,0.1);
  --bs-table-hover-color: #374649;
  --bs-table-hover-bg: rgba(0,0,0,0.075);
  margin-bottom: 1rem;
  color: #374649;
  vertical-align: top;
  border-color: #dee2e6;

}

.table > :not(caption) > * > * {
  padding: .5rem .5rem;
  background-color: var(--bs-table-bg);
  border-bottom-width: 1px;
  box-shadow: inset 0 0 0 9999px var(--bs-table-accent-bg)
}

.table > tbody {
  vertical-align: inherit
}

.table > thead {
  vertical-align: bottom;
}

.table > thead th[title] {
  cursor: pointer;
}

.table > :not(:last-child) > :last-child > * {
  border-bottom-color: none
}

.caption-top {
  caption-side: top
}

.table-sm > :not(caption) > * > * {
  padding: .25rem .25rem
}

.table-bordered > :not(caption) > * {
  border-width: 1px 0
}

.table-bordered > :not(caption) > * > * {
  border-width: 0 1px
}

.table-borderless > :not(caption) > * > * {
  border-bottom-width: 0
}

.table-striped > tbody > tr:nth-of-type(odd) {
  --bs-table-accent-bg: var(--bs-table-striped-bg);
  color: var(--bs-table-striped-color)
}

.table-active {
  --bs-table-accent-bg: var(--bs-table-active-bg);
  color: var(--bs-table-active-color)
}

.table-hover > tbody > tr:hover {
  --bs-table-accent-bg: var(--bs-table-hover-bg);
  color: var(--bs-table-hover-color)
}

.table-primary {
  --bs-table-bg: #cfe2ff;
  --bs-table-striped-bg: #c5d7f2;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #bacbe6;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #bfd1ec;
  --bs-table-hover-color: #000;
  color: #000;
  border-color: #bacbe6
}

.table-secondary {
  --bs-table-bg: #e2e3e5;
  --bs-table-striped-bg: #d7d8da;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #cbccce;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #d1d2d4;
  --bs-table-hover-color: #000;
  color: #000;
  border-color: #cbccce
}

.table-success {
  --bs-table-bg: #d1e7dd;
  --bs-table-striped-bg: #c7dbd2;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #bcd0c7;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #c1d6cc;
  --bs-table-hover-color: #000;
  color: #000;
  border-color: #bcd0c7
}

.table-info {
  --bs-table-bg: #cff4fc;
  --bs-table-striped-bg: #c5e8ef;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #badce3;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #bfe2e9;
  --bs-table-hover-color: #000;
  color: #000;
  border-color: #badce3
}

.table-warning {
  --bs-table-bg: #fff3cd;
  --bs-table-striped-bg: #f2e7c3;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #e6dbb9;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #ece1be;
  --bs-table-hover-color: #000;
  color: #000;
  border-color: #e6dbb9
}

.table-danger {
  --bs-table-bg: #f8d7da;
  --bs-table-striped-bg: #eccccf;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #dfc2c4;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #e5c7ca;
  --bs-table-hover-color: #000;
  color: #000;
  border-color: #dfc2c4
}

.table-light {
  --bs-table-bg: #f8f9fa;
  --bs-table-striped-bg: #ecedee;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #dfe0e1;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #e5e6e7;
  --bs-table-hover-color: #000;
  color: #000;
  border-color: #dfe0e1
}

.table-dark {
  --bs-table-bg: #212529;
  --bs-table-striped-bg: #2c3034;
  --bs-table-striped-color: #fff;
  --bs-table-active-bg: #373b3e;
  --bs-table-active-color: #fff;
  --bs-table-hover-bg: #323539;
  --bs-table-hover-color: #fff;
  color: #fff;
  border-color: #373b3e
}

.table-responsive {
  overflow-x: auto;
}

@media (max-width: 575.98px) {
  .table-responsive-sm {
      overflow-x:auto;
  }
}

@media (max-width: 767.98px) {
  .table-responsive-md {
      overflow-x:auto;
  }
}

@media (max-width: 991.98px) {
  .table-responsive-lg {
      overflow-x:auto;
  }
}

@media (max-width: 1199.98px) {
  .table-responsive-xl {
      overflow-x:auto;
  }
}

@media (max-width: 1399.98px) {
  .table-responsive-xxl {
      overflow-x:auto;
  }
}


table,table.table {
  background-color: white
}

table thead,table.table thead {
  font-size: 0.875rem;
  background-color: rgba(0, 0, 0, 0.05)
}

table tbody,table.table tbody {
  font-size: 0.875rem;
  background-color: white
}

table td>ul:last-child,table.table td>ul:last-child {
  margin-bottom: 0
}

.table>:not(:first-child) {
  border-top: none
}

// end tables from DP

.datalakepage .card {
  background-color: white;
}

.link-dark {
  color: RGBA(black, var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(black, var(--bs-link-underline-opacity, 1)) !important;
}